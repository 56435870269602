export async function useRedirectFromForm(body: CommonFormBody) {
  const formEmail = useState('email')
  const route = useRoute()
  const localePath = useLocalePath()

  formEmail.value = body.email

  if (route.path.includes('seller')) {
    await navigateTo({ path: localePath(`/auth/registration-seller`) })
  } else {
    await navigateTo({ path: localePath(`/auth/registration-customer`) })
  }
}