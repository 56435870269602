<script setup lang="ts">
interface Props {
  title: string
  type: string
  name: string
  placeholder: string
  buttonName: string
  alignment?: string
  placeholderColor?: string
  bcgInput?: string
  bcgButton?: string
  fzTitle?: string
  fzTitleMobile?: string
  textTransform?: string
  borderColor?: string
  colorError?: string
  formErrors?: string | null
}

const props = withDefaults(defineProps<Props>(), {
  placeholderColor: 'var(--c-grey-5)',
  bcgInput: 'var(--c-accent)',
  bcgButton: 'var(--c-white)',
  fzTitle: '28px',
  fzTitleMobile: '16px',
  textTransform: 'none',
  alignment: 'left',
  borderColor: 'transparent',
  colorError: 'var(--c-critical-accent)',
  formErrors: null
})

const emit = defineEmits(['submitForm'])
const input = ref<string | null>(null)

function submitForm() {
  const body = {
    [props.name]: input.value
  }
  emit(`submitForm`, body)
}
</script>

<template>
  <VeeForm
    class="form"
    @submit="submitForm"
  >
    <h3 class="form__title">{{ title }}</h3>
    <fieldset class='form__fieldset'>
      <div class="form__inputs">
        <VeeField
          v-model="input"
          :type="type"
          :name="name"
          :placeholder="placeholder"
          maxlength="35"
          class="form__input form__input--data"
        />
        <input
          type="submit"
          :value="buttonName"
          class="form__input form__input--submit"
        />
      </div>  
      <VeeErrorMessage
        v-if='!formErrors'
        :name="name"
        class="form__error"
      />
      <span
        v-if='formErrors'
        class="form__error"
      >
        {{ formErrors }}
      </span>
    </fieldset>

  </VeeForm>
</template>

<style lang="scss" scoped>
.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__title {
    font-size: v-bind(fzTitle);
    font-weight: 500;
    margin-block-end: 40px;
    text-align: v-bind(alignment);

    @media (width < 700px) {
      text-align: center;
      margin-block-end: 16px;
      font-size: v-bind(fzTitleMobile);
      font-weight: 600;
      line-height: 1.5;
      text-transform: v-bind(textTransform);
    }
  }

  &__fieldset {
    display: grid;
    grid-template-columns: minmax(min-content, 100%);
    border: none;
    padding: 0;
  }

  &__inputs {
    display: flex;
    flex-wrap: wrap;
    gap: 18px;

    @media (width < 700px) {
      flex-direction: column;
    }
  }

  &__input {
    border-radius: var(--border-radius);
    border: none;
    color: var(--c-text-primary);
    flex: 1 1 auto;

    &--data {
      padding: 12px;
      background: v-bind(bcgInput);
      font-size: 14px;
      border: 1px solid v-bind(borderColor);

      &::placeholder {
        color: v-bind(placeholderColor);
      }
    }

    &--submit {
      cursor: pointer;
      padding: 12px 33px;
      font-weight: 300;
      background: v-bind(bcgButton);

      @media (width < 700px) {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  
  &__error {
    color: v-bind(colorError);
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
  }
}
</style>
